import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import LogoSVG from '../images/THE_WANKERTON.svg';
import IconSVG from '../images/icon.svg'

import * as styles from "./header.module.css";

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>

      <button className={styles.toggleNavButton}>
        {/* Empty - SAME SIZE AS TOGGLE NAV BUTTON, this sits on left side of main site title to keep it centered*/}
      </button>

      <div className={styles.branding}>
        <Link to="/">
          <LogoSVG className={styles.logo}/>
        </Link>
      </div>

      <button
        className={styles.toggleNavButton}
        onClick={showNav ? onHideNav : onShowNav}
      >
        <Icon symbol="hamburger" />
      </button>
    </div>

    <nav className={cn(styles.nav, showNav && styles.showNav)}>
      <ul>
        <li>
          <Link to="/">HOME</Link>
        </li>
        <li>
          <Link to="/news">NEWS</Link>
        </li>
        <li>
          <Link to="/opinion">OPINION</Link>
        </li>
        <li>
          <Link to="/fiction">FICTION</Link>
        </li>
        

        {/*
        <li>
          <Link to="/politics">POLITICS</Link> ?? covered for now under NEWS
        </li>
        <li>
          <Link to="/entertainment">ENTERTAINMENT</Link> ?? covered for now under NEWS
        </li>
        <li>
          <Link to="/business">BUSINESS</Link> ?? covered for now under NEWS
        </li>
        <li>
          <Link to="/tech">TECH</Link> ?? think TECH is unneeded - fits under BUSINESS?
        </li>
        <li>f
          <Link to="/sports">SPORTS</Link> ?? covered for now under CULTURE
        </li>
        <li>
          <Link to="/video">VIDEO</Link> ?? not needed until start making videos (if ever)
        </li>
        <li>
          <Link to="/about">ABOUT</Link> ?? should appear at bottom of page instead. Not super pertinent info
        </li>
        <li>
          <Link to="/podcast">PODCAST</Link> ?? not needed until start making podcast
        </li>
        */}
      </ul>
    </nav>
  </div>
);

export default Header;
